<template>
  <div>

    <div class="mapChart" id="mainChart"></div>

    <div class="slogan">
      <div class="cn">享受泳乐 享受水</div>
      <div class="en">ENJOY WINGLOK ENJOY THE WATER</div>
    </div>

    <div class="info">

      <div class="title">全部设备</div>
      <div class="value">{{statistics.total}}</div>

      <div class="title">在线设备</div>
      <div class="value">{{statistics.online}}</div>

      <div class="title">告警设备</div>
      <div class="value">{{statistics.alarm}}</div>


    </div>

  </div>
</template>
<script>

import {mapState} from "vuex";

import * as echarts from 'echarts';
import 'echarts-extension-amap';
import china from "@/assets/map/china.json"

let myChart = null

export default {
  components: {},
  data() {
    return {
      statistics: {
        total: "--",
        online: "--",
        alarm: "--",
      },
    };
  },
  computed: {
    ...mapState({
      multipage: (state) => state.setting.multipage,
      user: (state) => state.account.user,
      vendor: (state) => state.account.vendor,
    }),
    avatar() {
      return `static/avatar/${this.user.avatar}`;
    },
  },
  methods: {
    setMapData(data){
      {
        // console.log("mapData", res);
        let option = {
          tooltip: {
            formatter: function (params) {
              return (
                  "项目名称："+params.data.name + "<br/>" +
                  "地址：" + params.data.address
              );
            }, //数据格式化
          },
          geo: {
            map: "china",
            roam: true, //不开启缩放和平移
            zoom: 1.5, //视角缩放比例
            center: [108.39, 39.9],
            label: {
              normal: {
                show: true,
                fontSize: "10",
                color: "rgba(0,0,0,0.5)",
              },
            },
            itemStyle: {
              normal: {
                // borderColor: "rgba(0, 0, 0, 0.2)",
                //  areaColor: 'rgba(0,60,153,0.8)',
                borderColor: "#999",
              },

            },
          },
          series: [

            {
              name: '在线设备',
              type: 'effectScatter',
              coordinateSystem: 'geo',
              data: data,
              symbolSize: 14,
              encode: {
                value: 2
              },
              showEffectOn: 'render',
              rippleEffect: {
                brushType: 'stroke',
                // number:3,
                scale:3.5,
                // period:3
              },
              hoverAnimation: true,
              label: {
                formatter: '{b}',
                position: 'right',
                show: false
              },
              itemStyle: {
                color: "#0084FF",
                // shadowBlur: 10,
                // shadowColor: '#333'
              },
              zlevel: 2
            }
          ],
        }
        myChart.setOption(option)
      }
    }
  },
  mounted() {
    echarts.registerMap("china", china);
    myChart = echarts.init(document.getElementById("mainChart"))
    myChart.on('click', function (params) {
      alert(params.data.id + ':' + params.data.value);
    });

    this.$get("web/device-project/map").then(suc=>{
      let projectData = []
      suc.data.forEach(item=>{
        projectData.push({
          ...item,
          value: [item.longitude, item.latitude]
        })
      })
      this.setMapData(projectData)
    })
  },
  created() {
    //获取统计数据
    this.$get("web/iot/device/overview").then(suc=>{
      this.statistics=suc.data
    })
  }
}
</script>
<style lang="less" scoped>
.mapChart {
  position: fixed;
  width: 90%;
  height: 90%;
  top: 0;
  left: 0;
}

.slogan{
  position: fixed;
  left: 0;
  bottom: 0;
  font-size: 16px;
  margin: 40px;

  .cn{
    font-size: 20px;
    letter-spacing: 18px;
  }
}

.info{
  position: fixed;
  right: 10px;
  top: 140px;
  margin: 20px;
  width: 240px;
  background: rgba(255,255,255,0.8);
  padding: 30px;
  border-radius: 10px;

  .title{
    font-size: 17px;
  }

  .value{
    font-size: 22px;
    color: #00BFFF;
    border-bottom: 1px solid #CCC;
    margin-top: 5px;
    margin-bottom: 20px;
  }
}

</style>
