import Vue from 'vue'
import ActionEdit from '../components/table/ActionEdit'
import ActionView from '../components/table/ActionView'
import ActionDelete from '../components/table/ActionDelete'
import ActionMessage from '../components/table/ActionMessage'
import ActionPush from '../components/table/ActionPush'
import ActionUpgrade from '../components/table/ActionUpgrade'


Vue.component("ActionEdit",ActionEdit)
Vue.component("ActionView",ActionView)
Vue.component("ActionDelete",ActionDelete)
Vue.component("ActionMessage",ActionMessage)
Vue.component("ActionPush",ActionPush)
Vue.component("ActionUpgrade",ActionUpgrade)


import CommonTable from '../components/table/CommonTable'
import Search from '../components/table/Search'
import RichTextField from '../components/form/RichTextField'
import ImageUpload from '../components/form/ImageUpload'
import MultiImageUpload from "../components/form/MultiImageUpload";
import DrawerForm from "../components/form/DrawerForm";

Vue.component("DrawerForm",DrawerForm)
Vue.component("Search",Search)
Vue.component("LSearch",Search)
Vue.component("CommonTable",CommonTable)
Vue.component("RichTextField",RichTextField)
Vue.component("ImageUpload",ImageUpload)
Vue.component("MultiImageUpload",MultiImageUpload)

import VideoUpload from "../components/form/VideoUpload"
Vue.component("VideoUpload",VideoUpload)

import VideoItem from "../components/form/VideoItem"
Vue.component("VideoItem",VideoItem)

import PageContent from "../components/layout/PageContent";
Vue.component("PageContent",PageContent)


import CommonForm from "../components/form/CommonForm";
Vue.component("CommonForm",CommonForm)

import IconSelect from "../components/form/IconSelect";
Vue.component("IconSelect",IconSelect)

import CsvImport from "../components/form/CsvImport";
Vue.component("CsvImport",CsvImport)

import ColorPicker from "../components/form/ColorPicker";
Vue.component("ColorPicker",ColorPicker)

import LocationPicker from "../components/form/LocationPicker";
Vue.component("LocationPicker",LocationPicker)

import PasswordInput from "../components/form/PasswordInput";
Vue.component("PasswordInput",PasswordInput)

import MenuTree from "../components/form/MenuTree";
Vue.component("MenuTree",MenuTree)

import InputDialog from "../components/dialog/InputDialog";
Vue.component("InputDialog",InputDialog)

import RangeDatePicker from "../components/table/RangeDatePicker";
Vue.component("RangeDatePicker",RangeDatePicker)
