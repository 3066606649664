<template>
    <div class="item">
        <div class="row">
            <a-upload
                    name="file"
                    class="image-upload"
                    listType="picture-card"
                    :showUploadList="false"
                    :beforeUpload="beforeUpload"
                    :action="uploadUrl"
                    @change="handleChange"
            >
                <div v-if="value">
                    <img class="image-upload" :src="value"/>
                </div>
                <div v-else>
                    <a-icon :type="loading ? 'loading' : 'plus'"/>
                    <div class="ant-upload-text">上传图片</div>
                </div>
            </a-upload>
            <a-button v-if="value" style="margin-left: 30px" @click="clickDelete"
                      shape="circle" ghost type="danger" icon="delete"></a-button>
        </div>

        <div class="image-hint" v-if="hint">
            <a-tag class="image-hint" color="green">{{hint}}</a-tag>
        </div>


    </div>
</template>

<script>
    import AUpload from 'ant-design-vue/es/upload/Upload'
    import config from '../../config/config'

    export default {
        components: {
            AUpload
        },
        props: {
            value: String,
            hint: String
        },
        data() {
            return {
                uploadUrl: config.apiServer + 'app/upload',
                loading: false
            }
        },
        methods: {
            clickDelete(){
                this.$emit('input', "")
            },
            beforeUpload(file) {
                const isImage = file.type.startsWith('image')
                if (!isImage) {
                    this.$message.error('请选择图片类型的文件!')
                }
                const isLt1M = file.size / 1024 / 1024 < 1
                if (!isLt1M) {
                    this.$message.error('图片大小不能超过 1MB!')
                }
                return isImage && isLt1M
            },
            customRequest(data) {
                console.log('customRequest')
                console.log(data)
            },
            handleChange(info) {
                console.log('执行子组件')
                console.log(info)
                if (info.file.status === 'uploading') {
                    this.loading = true
                    return
                }

                if (info.file.status === 'done') {
                    this.loading = false
                    let value = info.file.response.data
                    this.$emit('input', value)
                    return
                }

            }
        }
    }
</script>

<style lang="less" scoped>
    .item {
        display: flex;
        align-items: center;

        .row{
            display: flex;
            align-items: center;
        }
    }

    .image-upload {
        width: auto;
        max-width: 240px;
    }

    .image-hint {
        margin-left: 15px;
    }

</style>
