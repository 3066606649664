import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import request from '@/utils/request'
import db from '@/utils/localstorage'


import "./_dependency/ant-design"
import "./_dependency/vue-video-player"
import "./_dependency/amap"
import "./_dependency/bmap"
import "./_dependency/apex-charts"
import "./_dependency/vue-quill-editor"
import "./_dependency/my-components"
import "./_dependency/my-icon"
import "./_dependency/vue-color"

import '@/utils/install'


Vue.use(db)

Vue.use({
  install (Vue) {
    Vue.prototype.$db = db
  }
})


/* eslint-disable no-new */
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
