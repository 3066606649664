<template functional>

  <a-sub-menu :key="props.menu.path" v-if="props.menu.hasChildren">

    <span slot="title">
<!--      <a-icon :type="props.menu.icon" />-->
      <span>{{ props.menu.name }}</span>
    </span>

    <template v-for="item in props.menu.children">

      <a-menu-item v-if="!item.children" :key="item.path">
        <a :href="'#'+item.path">
<!--          <a-icon type="item.icon" />-->
          <span>{{ item.name }}</span>
        </a>
      </a-menu-item>
      <recursion-sub-menu v-else :menu="item" />
    </template>
  </a-sub-menu>

  <a-menu-item :key="props.menu.path" v-else>
    <a :href="'#'+props.menu.path">
<!--      <a-icon :type="props.menu.icon" />-->
      <span>{{ props.menu.name }}</span>
    </a>
  </a-menu-item>

</template>


<script>
  export default {
    name: 'RecursionSubMenu',
    props: {
      menu: {
        type: Object,
        required: true
      }
    },

  }
</script>
<style>

</style>
