<template>
  <div class="layout">

    <div class="layout-header">
      <img class="logo" src="../../assets/yongle-logo.png"/>
      <div class="title">
      </div>
      <div style="flex: 1;height: 40px">
      </div>

      <div class="time">{{time}}</div>

      <recursion-menu theme="dark" :menuData="menuData" :collapsed="true" mode="horizontal"/>
      <div style="width: 80px"></div>
      <header-avatar></header-avatar>
    </div>

    <div class="layout-body">
      <!--            <div class="sider">-->
      <!--                <recursion-menu :theme="theme" :menuData="menuData" :collapsed="true"/>-->
      <!--            </div>-->

      <div class="content">
        <router-view></router-view>
      </div>
      <!--      <a-layout-footer style="padding: .29rem 0" class="copyright">-->
      <!--        <global-footer :copyright="copyright"/>-->
      <!--      </a-layout-footer>-->
    </div>
  </div>
</template>

<script>
import HeaderAvatar from "./HeaderAvatar";
import RecursionMenu from "../menu/RecursionMenu";
import {mapState, mapMutations} from 'vuex'
import moment from 'moment'

let menuData = []

export default {
  components: {HeaderAvatar, RecursionMenu},
  data() {
    return {
      collapsed: false,
      menuData: menuData,
      time:""
    }
  },
  computed: {

    ...mapState({
      sidebarOpened: state => state.setting.sidebar.opened,
      isMobile: state => state.setting.isMobile,
      theme: state => state.setting.theme,
      layout: state => state.setting.layout,
      copyright: state => state.setting.copyright,
      fixSiderbar: state => state.setting.fixSiderbar,
      fixHeader: state => state.setting.fixHeader,
      settingBar: state => state.setting.settingBar.opened
    })
  },
  methods: {
    ...mapMutations({setSidebar: 'setting/setSidebar'}),
    toggleCollapse() {
      this.collapsed = !this.collapsed
      this.setSidebar(!this.collapsed)
      triggerWindowResizeEvent()
    },
    onDrawerChange(show) {
      this.collapsed = show
    },
    onMenuSelect() {
      this.toggleCollapse()
    }
  },
  beforeCreate() {
    let routers = this.$db.get('USER_ROUTER')
    menuData = routers.find((item) => item.path === '/').children.filter((menu) => {
      console.log(menu.name + ":" + menu.hidden)
      return !menu.hidden
      let meta = menu.meta
      if (typeof meta.isShow === 'undefined') {
        return true
      } else return meta.isShow
    })
  },
  created() {
    let timeFormat = "YYYY-MM-DD HH:mm"
    this.time = moment().format(timeFormat)
    setInterval(()=>{
      this.time = moment().format(timeFormat)
    },30000)
  }

}
</script>

<style lang="less" scoped>

.layout {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: transparent;
}

.layout-header {
  background: #96D0F2;
  display: flex;
  align-items: center;
  padding: 6px 25px;
  z-index: 2;
  font-weight: 500;

  .time{
    margin-right: 180px;
    font-size: 18px;
    color: #FFF;
  }

  .logo {
    width: 120px;
    height: 120px;
    position: fixed;
    left: 40px;
    top: 20px;
    border-radius: 50%;
    object-fit:contain;
    background:#FFF;
    padding:10px
  }

  .title {
    width: 200px;
    font-size: 18px;
    color: #164c97;
  }
}

.layout-body {
  flex: 1;
  display: flex;
  background: transparent;
  overflow-y: auto;


  .sider {
    width: auto;
    height: 100%;
    overflow-y: auto;
    background: rgba(255, 255, 255, 0.1);
    /*box-shadow: 0 0 5px #888;*/
    padding-top: 20px;
    border-right: 1px solid #EEE;

    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
    overflow-x: hidden;
    overflow-y: auto;
  }

  .sider::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }

  .content {
    flex: 1;
    height: 100%;
    overflow-y: auto;
    background: transparent;
  }


}

</style>

