import LoginPage from '../views/login/Login'
import HomePage from '../views/home/HomePage'

export default {
  // apiServer:'http://localhost:4080/',
  apiServer:'https://iot.yongl.net/iot-api/',
  theme: 'light',
  layout: 'side',
  logo:"",
  loginTitle: '泳乐之家',
  systemName: '泳乐之家',
  copyright: '泳乐之家',
  multipage: false,
  fixSiderbar: true,
  fixHeader: true,
  color: '#27d85a',

  loginPage:LoginPage,
  homePage:HomePage
}

