<!--递归，无限层级导航栏-->
<template>
  <div class="menu-container" v-bind:class="{'menu-container-collapsed':collapsed}">
    <a-menu :mode="mode" :theme="theme" :openKeys="openKeys" :selectedKeys="selectedKeys"
            @openChange="onOpenChange" style="background: transparent;border-right: none"
            :inline-collapsed="collapsed">
      <template v-for="menu in menuData">
        <recursion-sub-menu :menu="menu"/>
      </template>
    </a-menu>
    <div class="collapsed-icon" @click="collapsed=!collapsed" v-if="mode=='inline'">
      <a-icon v-if="collapsed" type="right"/>
      <a-icon v-else type="left"/>
    </div>
  </div>
</template>


<script>
import RecursionSubMenu from './RecursionSubMenu.vue'

export default {
  components: {RecursionSubMenu},
  name: 'IMenu',
  props: {
    menuData: {
      type: Array,
      required: true
    },
    theme: {
      type: String,
      required: false,
      default: 'dark'
    },
    mode: {
      type: String,
      required: false,
      default: 'inline'
    },
    // collapsed: {
    //     type: Boolean,
    //     required: false,
    //     default: false
    // }
  },
  data() {
    return {
      collapsed: false,
      openKeys: [],
//        cachedOpenKeys:[],
      selectedKeys: [],
    }
  },
  created() {
    this.updateMenu()
  },
  watch: {
    collapsed(val) {
      if (val) {
        this.cachedOpenKeys = this.openKeys
        this.openKeys = []
      } else {
        this.openKeys = this.cachedOpenKeys
      }
    },
    '$route': function () {
      this.updateMenu()
    }
  },
  methods: {
    onOpenChange(openKeys) {
      //自动收缩其他项
      if (openKeys.length >= 2) {
        this.openKeys = this.getSubPath(openKeys[openKeys.length - 1])
      } else {
        this.openKeys = openKeys
      }
    },
    getSubPath(path) {
      var subPathArr = []
      subPathArr.push(path)
      while (path.lastIndexOf("/") > 0) {
        path = path.substr(0, path.lastIndexOf("/"))
        subPathArr.push(path)
      }
      return subPathArr
    },
    updateMenu() {
      var path = this.$route.path
      this.openKeys = this.getSubPath(path)
      this.selectedKeys = this.getSubPath(path)
    },
  },
}
</script>

<style lang="less">

.ant-menu-submenu > .ant-menu {
  background: #96D0F2 !important;
}

.ant-menu-sub.ant-menu-inline {
  background: transparent !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  //background: rgba(255, 255, 255, 0.7) !important;
}


.ant-menu.ant-menu-dark .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected{
  background-color: transparent !important;
}

.ant-menu-dark.ant-menu-horizontal > .ant-menu-item, .ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {

  border-radius: 10px;
}

.menu-container {
  position: relative;
  min-width: 200px;

  .collapsed-icon {
    position: absolute;
    bottom: 50%;
    right: 0;
    padding: 10px 2px;
    background: #444;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    box-shadow: 0 0 4px #AAA;
    cursor: pointer;
  }

  .collapsed-icon:hover {
    background: #EEE;
  }
}

.menu-container-collapsed {
  min-width: 0px;
  padding: 0 5px;
}

.ant-menu-dark .ant-menu-item-selected > a, .ant-menu-dark .ant-menu-item-selected > a:hover {
  color: #0084FF !important;
}

.ant-menu-dark .ant-menu-item:hover, .ant-menu-dark .ant-menu-item-active, .ant-menu-dark .ant-menu-submenu-active, .ant-menu-dark .ant-menu-submenu-open, .ant-menu-dark .ant-menu-submenu-selected, .ant-menu-dark .ant-menu-submenu-title:hover{
  color: #0084FF !important;
}

</style>
