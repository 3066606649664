<template>
  <div>
    <a-range-picker
      @change="dateChange"
      v-model="date"
    ></a-range-picker>
  </div>
</template>

<script>
  import moment from 'moment'
  export default {
    data(){
      return {
        date:[]
      }
    },
    methods:{
      dateChange(dates,dateStrings){
        let timeFrom = parseInt(moment(dateStrings[0], "YYYY-MM-DD").format('X'))
        let timeTo = parseInt(moment(dateStrings[1], "YYYY-MM-DD").format('X')) + 24*60*60
        this.$emit("change",timeFrom,timeTo)
      },
      clear(){
        this.date=[]
        this.$emit("change",0,0)
      }
    }
  }
</script>

<style scoped>

</style>
