<template>
    <div class="login-bg">

<!--      <img src="@/assets/img/bg_login.jpg" style="width: 100%;height: 100%;position: fixed;left: 0;top: 0;object-fit:cover;opacity: 0.5"/>-->

      <div class="login-form">

        <div class="header">
          <img  class="header-logo" :src="logo" v-if="logo">
          <span class="header-title">{{loginTitle}}</span>
        </div>

        <a-form-model ref="loginForm" :model="model" :rules="rules">

          <a-form-model-item prop="username">
            <a-input size="large" v-model="model.username" >
              <a-icon slot="prefix" type="user"></a-icon>
            </a-input>
          </a-form-model-item>

          <a-form-model-item prop="password">
            <a-input size="large" v-model="model.password" type="password" >
              <a-icon slot="prefix" type="lock"></a-icon>
            </a-input>
          </a-form-model-item>

<!--          <a-form-model-item prop="kaptcha">-->
<!--            <a-input size="large" v-model="model.kaptcha" >-->
<!--              <a-icon slot="prefix" type="code" style="margin-right: 10px"></a-icon>-->
<!--              <img slot="suffix" @click="getKaptcha" class="kaptcha" :src="kaptchaImage"/>-->
<!--            </a-input>-->
<!--          </a-form-model-item>-->

          <a-form-model-item>
            <a-button :loading="loading"  size="large" block type="primary" class="btn-login" @click="clickLogin">登录</a-button>
          </a-form-model-item>

        </a-form-model>

      </div>

      <div class="beian" @click="clickBeiAn">粤ICP备18035117号</div>

    </div>
</template>

<script>

  import { mapMutations, mapState } from 'vuex'

    export default {
        data(){
          return {
            model: {
              username: "",
              password: "",
              kaptcha:"",
              sessionId:""
            },
            rules: {
              username: [
                {required: true, message: '请输入用户名'},
              ],
              password: [
                {required: true, message: '请输入密码'},
              ],
              kaptcha:[
                {required: true, message: '请输入验证码'},
              ]
            },
            loading:false,
            kaptchaImage:""
          }

        },
      computed: {
        ...mapState({
          loginTitle: state => state.setting.loginTitle,
          systemName: state => state.setting.systemName,
          logo: state => state.setting.logo,
          copyright: state => state.setting.copyright,
        })
      },

      created () {
        this.$db.clear()
        this.$router.options.routes = []
        this.getKaptcha()
      },
      methods:{
        ...mapMutations({
          setToken: 'account/setToken',
          setExpireTime: 'account/setExpireTime',
          setPermissions: 'account/setPermissions',
          setRoles: 'account/setRoles',
          setUser: 'account/setUser',
          setVendor: 'account/setVendor',
          setTheme: 'setting/setTheme',
          setLayout: 'setting/setLayout',
          setMultipage: 'setting/setMultipage',
          fixSiderbar: 'setting/fixSiderbar',
          fixHeader: 'setting/fixHeader',
          setColor: 'setting/setColor'
        }),

        clickLogin(){
          this.$refs.loginForm.validate(valid => {
            if (!valid) {
              return
            }
            this.doLogin()
          })
        },

        doLogin(){

          this.loading = true
          this.$post('login', this.model).then((r) => {
            let data = r.data
            this.saveLoginData(data)
            console.log("saveLoginData")
            setTimeout(() => {
              this.loading = false
            }, 500)
            this.$router.push('/device')
            console.log("goto home")
          }).catch((e) => {
            this.loading = false
            this.getKaptcha()
          })
        },

        getKaptcha(){
          this.$get('kaptcha').then(r=>{
            this.kaptchaImage = r.data.image
            this.model.sessionId = r.data.sessionId
          })
        },

        saveLoginData (data) {
          this.setToken(data.token)
          this.setExpireTime(data.expireTime)
          this.setUser(data.user)
          this.setVendor(data.vendor)
          this.setPermissions(data.permissions)
          this.setRoles(data.roles)

          //暂时不支持自定义主题
          // this.setTheme(data.config.theme)
          // this.setLayout(data.config.layout)
          // this.setMultipage(data.config.multiPage === '1')
          // this.fixSiderbar(data.config.fixSiderbar === '1')
          // this.fixHeader(data.config.fixHeader === '1')
          // this.setColor(data.config.color)
        },

        clickBeiAn(){
          window.open("https://beian.miit.gov.cn/")
        }
      }

    }
</script>

<style scoped lang="less">
.login-bg{
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
  align-items: center;
  /*background: #f0f2f5 url('https://gw.alipayobjects.com/zos/rmsportal/TVYTbAXWheQpRcWDaDMu.svg') no-repeat center 110px;*/
  /*background-size: 100%;*/

}

.login-form{
  width: 440px;
  background: rgba(255,255,255,0.4);
  margin:auto;
  padding: 50px 60px;
  border-radius: 8px;
  box-shadow: 0 10px 20px rgba(0,0,0,0.2);
  z-index: 1;
  backdrop-filter: blur(5px);
}

.header{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
}

.header-logo{
  width: 250px;
  height: auto;
  margin: 20px 0;
}

.header-title{
  font-size: 28px;
  color: #164c97;
}



  .kaptcha{
    min-width: 80px;
    width:auto;height: 32px;
    background: #F0FFF0;border-radius: 3px;border: 1px solid #F0F0F0;
    cursor: pointer;
  }


  .beian{
    cursor: pointer;
    padding: 5px 20px;
    color: #000;
    font-size: 12px;
    opacity: 0.8;
    text-shadow: 0px 0px 3px #FFF;
  }



</style>
