<template>
  <div>

    <a-input-search placeholder="" :value="value" readOnly @search="showModal">
      <div v-if="value" class="current-color" v-bind:style="colorStyle"
           slot="addonBefore"></div>
      <a-icon v-if="value" slot="suffix" type="close-circle" @click="clickClear"/>
      <a-button slot="enterButton" @click="visible=true">选择颜色</a-button>
    </a-input-search>

    <a-modal v-model="visible" title="选择颜色" @ok="handleOk" centered>
      <div class="color-bg" v-bind:style="bgStyle">
        <photoshop-picker v-model="colors" class="color-picker"/>
      </div>
    </a-modal>

  </div>
</template>

<script>
  export default {
    props:["value"],
    data() {
      return {
        visible: false,
        colors: {
          hex: '#FFFFFF',
          hex8: '#194D33A8',
          hsl: {h: 150, s: 0.5, l: 0.2, a: 1},
          hsv: {h: 150, s: 0.66, v: 0.30, a: 1},
          rgba: {r: 25, g: 77, b: 51, a: 1},
          a: 1
        }
      }
    },
    computed: {
      colorStyle(){
        return {background: this.value}
      },
      bgStyle() {
        return {background: this.colors.hex}
      }
    },
    methods: {
      showModal(){
        this.colors.hex = this.value
        this.visible=true
      },
      clickClear(){
        this.$emit("input","")
      },
      handleOk() {
        this.$emit("input",this.colors.hex)
        this.visible=false
      }
    }
  }
</script>

<style scoped>
.current-color{
  width: 100px;
  height: 22px;
  border-radius: 4px;
  border: 1px solid #000;
}
  .color-bg{
    display: flex;
    justify-content: center;
    align-items: center;;
    padding: 30px;
    border-radius: 5px;
  }

  .color-picker{
    width: 220px;background: #F0F0F0;
  }

</style>
